import React from 'react'
import { IoIosArrowForward } from "react-icons/io";

const ProSec1 = () => {
  return (
    <>
<section className="Pro-sec-1">
    <div className="main-Prosec-1">
        <p>Home</p><IoIosArrowForward className='arr'  />
        <p>Shop</p><IoIosArrowForward className='arr' />
<button>Asgaard sofa</button>
    </div>
</section>
    </>
  )
}

export default ProSec1
