import React from 'react'

const Footer = () => {
  return (
    <>
      <footer>
        <div className="main-footer">
            <div className="footer-up">
            <div className="foot-1">
                <h1>Funiro.</h1>
                <p>400 University Drive Suite 200 Coral Gables,
                </p>
                <h6>FL 33134 USA</h6>
            </div>
            <div className="foot-2">
                <h1>Links</h1>
                <ul>
                    <li>Home</li>
                    <li>Shop</li>
                    <li>About</li>
                    <li>Contact</li>
                </ul>
            </div>
            <div className="foot-3">
            <h1>Help</h1>
                <ul>
                    <li>Payment Options</li>
                    <li>Returns</li>
                    <li>Privacy Policies</li>
                </ul>
            </div>
            <div className="foot-4">
                <h1>Newsletter</h1>
                <div className="foot-4-input-feild">
                    <input placeholder='Enter Your Email Address' type="text" />
                    <button>SUBSCRIBE</button>
                </div>
            </div>
            </div>
            <div className="footer-down">
                <p>2023 furino. All rights reverved</p>
            </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
