import React, { useState } from 'react'
import g173 from '../../imgages/G173.png'
import g12 from '../../imgages/G94.png'
import sdar from '../../imgages/sdar.png'
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { AiFillTwitterCircle } from "react-icons/ai";
import ADDTOCARD from "../AddtoCard/ADDTOCARD";
const ProSec2 = () => {
  const [count,setcount]=useState(0);
  const handleClick = () => {
    setcount(count+1);
  }
  const handleClick2 = () => {
    if(count>0){
      setcount(count-1);

    }
  }
  const isopen = () => {
    const sidebar = document.querySelector(".AddtoCard");
    const close = document.querySelector(".cancle");
  
    sidebar.classList.add("add");
    document.body.classList.add("no-scroll");
  
    close.addEventListener("click", () => {
      sidebar.classList.remove("add");
      document.body.classList.remove("no-scroll"); 
    });
  };

  return (
    <>
  <ADDTOCARD count={count} />
<section className="Pro-sec-2">
    <div className="main-Prosec-2">
      <div className="main-Prosec-2-left">
        <div className="main-Prosec-2-left-left">
          <div className="pod-1"><img src={g12} alt="" /></div>
          <div className="pod-1"><img src={g12} alt="" /></div>
          <div className="pod-1"><img src={g12} alt="" /></div>
          <div className="pod-1"><img src={g12} alt="" /></div>
        </div>
        <div className="main-Prosec-2-left-right">
          <img src={g173} alt="" />
        </div>
      </div>
      <div className="main-Prosec-2-right">
        <div className="main-Prosec-2-right-prizing">
          <h1>Asgaard sofa</h1>
          <h2>Rs. 250,000.00</h2>
        </div>
        <div className="main-Prosec-2-right-reviews">
<div className="k14-1">
  <img src={sdar} alt="" />
</div>
<div className="k14-2">
  <p>5 Customer Review</p>
</div>
        </div>
        <div className="main-Prosec-2-right-des">
          <p>Setting the bar as one of the loudest speakers in its class, the Kilburn is a compact, stout-hearted hero with a well-balanced audio which boasts a clear midrange and extended highs for a sound.</p>
        </div>
        <div className="main-Prosec-2-right-size">
          <h1>Size</h1>
          <div className="size-box">
            <div className="size-box-1">L</div>
            <div className="size-box-1">XL</div>
            <div className="size-box-1">XS</div>
          </div>
        </div>
        <div className="main-Prosec-2-right-color">
          <h1>Color</h1>
          <div className="color-box">
            <div className="color-box-1"></div>
            <div className="color-box-1"></div>
            <div className="color-box-1"></div>
          </div>
        </div>
        <div className="main-Prosec-2-right-btn">
          <div className="count">
            <span onClick={handleClick2}>-</span>
            <p id='count'>{count}</p>
            <span onClick={handleClick}>+</span>
          </div>
          <button onClick={isopen}>Add to Cart</button>
          <button>+ Compare</button>

        </div>
        <div className="main-Prosec-2-right-list">
          <div className="list">
          <p>SKU </p><span>: SS001</span>  
          </div>
          <div className="list">
          <p>Category </p><span>: Sofas</span>  
          </div>
          <div className="list">
          <p>Tags </p><span>: Sofa, Chair, Home, Shop</span>  
          </div>
          <div className="list">
          <p>Share </p><span>: <FaFacebook /> <FaLinkedin /> <AiFillTwitterCircle />  </span>  
          </div>
        </div>
      </div>
    </div>  
</section>
    </>
  )
}

export default ProSec2
