import React from 'react'
import Slider from "react-slick";
import slide1 from "../../imgages/slider-img.png";
const Section4 = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        // arrows:false
      };
  return (
    <>
      <section className="Section-4">
        <div className="main-Section-4">
            <div className="main-Section-4-left">
                <h1>50+ Beautiful rooms 
                inspiration</h1>
                <p>Our designer already made a lot of beautiful prototipe of rooms that inspire you</p>
                <button>Explore More</button>
            </div>
            <div className="main-Section-4-right">
  <div className="slider-container">
    <Slider {...settings}>
      <div className="slide-wrapper">
        <img src={slide1} alt="" />
      </div>
      <div className="slide-wrapper">
        <img src={slide1} alt="" />
      </div>
      <div className="slide-wrapper">
        <img src={slide1} alt="" />
      </div>
    </Slider>
  </div>
</div>

        </div>
      </section>
    </>
  )
}

export default Section4
