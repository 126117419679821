import React from 'react'
import Header from '../components/header/Header'
import HeroSection from '../components/Herosection/Herosection'
import Section2 from '../components/SEction-2/Section2'
import Section3 from '../components/Section-3/Section3'
import Section4 from '../components/Section-4/Section4'
import Section5 from '../components/Section-5/Section5'
import Footer from '../components/footer/Footer'

const Landing = () => {
  return (
    <>
      <Header />
      <HeroSection />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Footer />
    </>
  )
}

export default Landing
