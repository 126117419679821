import React from 'react'
import cardbg from '../../imgages/card-bg.png'
import cardbg2 from '../../imgages/card-bg-2.png'
import cardbg3 from '../../imgages/card-bg-3.png'

const Section2 = () => {
  return (
    <>
      <section className='Section-2'>
        <div className="container-sec-2">
            <div className="main-section-2">
                <div className="main-section-2-heading">
                    <h1>Browse The Range</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
                <div className="main-section-2-card-box">
                    <div className="card-section-2">
                        <div className="card-section-2-img">
                            <img src={cardbg} alt="" />
                        </div>
                        <div className="card-section-2-name">
                            <h1>Dining</h1>
                        </div>
                    </div>
                    <div className="card-section-2">
                        <div className="card-section-2-img">
                            <img src={cardbg2} alt="" />
                        </div>
                        <div className="card-section-2-name">
                            <h1>Living</h1>
                        </div>
                    </div>
                    <div className="card-section-2">
                        <div className="card-section-2-img">
                            <img src={cardbg3} alt="" />
                        </div>
                        <div className="card-section-2-name">
                            <h1>Bedroom</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default Section2
