import React from 'react'
import llb from '../../imgages/Feature.png'
const Shopsection3 = () => {
  return (
    <>
         <section className="section-5">
      <div className="marquee-container">
      <div className="marquee">
        <img src={llb} alt=""/>
        <img src={llb} alt=""/>
 
      </div>
      <div className="marquee">
        <img src={llb} alt=""/>
        <img src={llb} alt=""/>
 
      </div>
    </div>
    </section>
    </>
  )
}

export default Shopsection3
