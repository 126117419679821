import React from 'react';
import card1 from '../../imgages/card-bg-sec-3.png';
import like from '../../imgages/likeshare.png';
import { useNavigate } from 'react-router-dom';
const Shopsection2 = () => {
  // Sample data for the cards
  const cardsData = Array(16).fill({
    title: 'Syltherine',
    description: 'Stylish cafe chair',
    price: 'Rp 2.500.000',
    originalPrice: 'Rp 3.500.000',
    imgSrc: card1,
  });
  const navigate = useNavigate();
const func=()=>{
  navigate('/Product');
}
  return (
    <section className="Shop-section-2">
      <div className="main-Shop-section-2">
        <div className="main-section-3-card-box">
          {cardsData.map((card, index) => (
            <div className="card-section-3" key={index} > 
              <div className="card-section-3-img">
                <img src={card.imgSrc} alt={card.title} />
              </div>
              <div className="card-section-3-details">
                <div className="card-section-3-details-heading">
                  <h1>{card.title}</h1>
                  <p>{card.description}</p>
                </div>
                <div className="card-section-3-details-prizing">
                  <h3>{card.price}</h3>
                  <p>{card.originalPrice}</p>
                </div>
              </div>
              <div className="card-section-3-hover">
                <button onClick={func}>Add to cart</button>
                <div className="card-section-3-hover-det">
                  <img src={like} alt="like and share" />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="main-Shop-section-2-btn">
          <div className="box-1">1</div>
          <div className="box-1">2</div>
          <div className="box-1">3</div>
          <button>Next</button>
        </div>
      </div>
    </section>
  );
};

export default Shopsection2;
