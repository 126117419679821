import React from 'react'
import center from '../../imgages/marqueecenter.png'
import left1 from '../../imgages/marqueeleft1.png'
import left2 from '../../imgages/marqueeleft2.png'
import left3 from '../../imgages/marqueeleft3.png'
import left4 from '../../imgages/marqueeleft4.png'
import right1 from '../../imgages/marqueeright1.png'
import right2 from '../../imgages/marqueeright2.png'
import right3 from '../../imgages/marqueeright3.png'
import right4 from '../../imgages/marqueeright4.png'
const Section5 = () => {
  return (
    <>
      <section className="Section-5">
        <div className="main-section-5">
            <div className="main-section-5-heading">
                <p>Share your setup with</p>
                <h1>#FuniroFurniture</h1>
            </div>
            <div className="marquee-box">
              <div className="marquee-1">
                <div className="marquee-1-up">
                  <div className="marquee-1-up-left">
                    <img src={left2} alt="" />
                  </div>
                  <div className="marquee-1-up-right">
                  <img src={left1} alt="" />
                    
                  </div>
                </div>
                <div className="marquee-1-down">
                <div className="marquee-1-down-left">
                    <img src={left3} alt="" />
                  </div>
                  <div className="marquee-1-down-right">
                  <img src={left4} alt="" />
                    
                  </div>
                </div>
              </div>
              <div className="marquee-2">
                <img src={center} alt="" />
              </div>
              <div className="marquee-3">
              <div className="marquee-3-up">
                  <div className="marquee-3-up-left">
                    <img src={right1} alt="" />
                  </div>
                  <div className="marquee-3-up-right">
                  <img src={right2  } alt="" />
                    
                  </div>
                </div>
                <div className="marquee-3-down">
                <div className="marquee-3-down-left">
                    <img src={right3} alt="" />
                  </div>
                  <div className="marquee-3-down-right">
                  <img src={right4} alt="" />
                    
                  </div>
                </div>
              </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default Section5
