import React from 'react';
import card1 from '../../imgages/card-bg-sec-3.png';
import like from '../../imgages/likeshare.png';
import { useNavigate } from 'react-router-dom';

const Section3 = () => {
  const cardData = [
    { title: 'Syltherine', description: 'Stylish cafe chair', price: 'Rp 2.500.000', originalPrice: 'Rp 3.500.000', image: card1 },
    { title: 'Syltherine', description: 'Stylish cafe chair', price: 'Rp 2.500.000', originalPrice: 'Rp 3.500.000', image: card1 },
    { title: 'Syltherine', description: 'Stylish cafe chair', price: 'Rp 2.500.000', originalPrice: 'Rp 3.500.000', image: card1 },
    { title: 'Syltherine', description: 'Stylish cafe chair', price: 'Rp 2.500.000', originalPrice: 'Rp 3.500.000', image: card1 },
    { title: 'Syltherine', description: 'Stylish cafe chair', price: 'Rp 2.500.000', originalPrice: 'Rp 3.500.000', image: card1 },
    { title: 'Syltherine', description: 'Stylish cafe chair', price: 'Rp 2.500.000', originalPrice: 'Rp 3.500.000', image: card1 },
    { title: 'Syltherine', description: 'Stylish cafe chair', price: 'Rp 2.500.000', originalPrice: 'Rp 3.500.000', image: card1 },
    { title: 'Syltherine', description: 'Stylish cafe chair', price: 'Rp 2.500.000', originalPrice: 'Rp 3.500.000', image: card1 },
    // Add more card objects here
  ];
  const navigate = useNavigate();
const func=()=>{
  navigate('/Product');
}
  return (
    <section className='Section-3'>
      <div className="main-section-3">
        <div className="main-section-3-heading">
          <h1>Our Products</h1>
        </div>
        <div className="main-section-3-card-box">
          {cardData.map((card, index) => (
            <div className="card-section-3" key={index} >
              <div className="card-section-3-img">
                <img src={card.image} alt={card.title} />
              </div>
              <div className="card-section-3-details">
                <div className="card-section-3-details-heading">
                  <h1>{card.title}</h1>
                  <p>{card.description}</p>
                </div>
                <div className="card-section-3-details-prizing">
                  <h3>{card.price}</h3>
                  <p>{card.originalPrice}</p>
                </div>
              </div>
              <div className="card-section-3-hover">
                <button onClick={func}>Add to cart</button>
                <div className="card-section-3-hover-det">
                  <img src={like} alt="like and share" />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="main-section-3-btn">
          <button>Show More</button>
        </div>
      </div>
    </section>
  );
};

export default Section3;
