import React from 'react'
import logo from '../../imgages/logo.png'
import icon3 from '../../imgages/akar-icons_heart.png'
import icon2 from '../../imgages/akar-icons_search.png'
import icon4 from '../../imgages/ant-design_shopping-cart-outlined.png'
import icon1 from '../../imgages/mdi_account-alert-outline.png'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <>
<header>
    <div className="containerheader">
        <div className="main-header">
            <div className="head-1">
                <img src={logo} alt="" />
            </div>
            <div className="head-2">
                <ul>
                    <Link className='Link' to="/"><li>Home</li></Link>
                    <Link className='Link' to="/shop"><li>Shop</li></Link>
                    <Link className='Link' to="/product"><li>About</li></Link>
                    <li>Contact</li>
                </ul>
            </div>
            <div className="head-3">
                <ul>
                    <li>
                        <img src={icon1} alt="" />
                    </li>
                    <li>
                        <img src={icon2} alt="" />
                    </li>
                    <li>
                        <img src={icon3} alt="" />
                    </li>
                    <li>
                        <img src={icon4} alt="" />
                    </li>
                </ul>
            </div>

        </div>
    </div>
</header>
    </>
  )
}

export default Header