import React from 'react'
import card1 from '../../imgages/card-bg-sec-3.png'
import like from '../../imgages/likeshare.png'
const ProSec4 = () => {
  return (
    <>
      <section className="proSec4">
        <div className="main-proSec4">
          <div className="main-proSec4-heading">
            <h1>Related Products</h1>
            <div className="main-section-3-card-box">
 
    <div className="card-section-3">
        <div className="card-section-3-img">
            <img src={card1} alt="" />
        </div>
        <div className="card-section-3-details">
            <div className="card-section-3-details-heading">
                <h1>Syltherine</h1>
                <p>Stylish cafe chair</p>
            </div>
            <div className="card-section-3-details-prizing">
                <h3>Rp 2.500.000</h3>
                <p>Rp 3.500.000</p>
            </div>
        </div>
        <div className="card-section-3-hover">
            <button>Add to cart</button>
            <div className="card-section-3-hover-det">
                <img src={like} alt="" />
            </div>
        </div>
    </div>
    <div className="card-section-3">
        <div className="card-section-3-img">
            <img src={card1} alt="" />
        </div>
        <div className="card-section-3-details">
            <div className="card-section-3-details-heading">
                <h1>Syltherine</h1>
                <p>Stylish cafe chair</p>
            </div>
            <div className="card-section-3-details-prizing">
                <h3>Rp 2.500.000</h3>
                <p>Rp 3.500.000</p>
            </div>
        </div>
        <div className="card-section-3-hover">
            <button>Add to cart</button>
            <div className="card-section-3-hover-det">
                <img src={like} alt="" />
            </div>
        </div>
    </div>
    <div className="card-section-3">
        <div className="card-section-3-img">
            <img src={card1} alt="" />
        </div>
        <div className="card-section-3-details">
            <div className="card-section-3-details-heading">
                <h1>Syltherine</h1>
                <p>Stylish cafe chair</p>
            </div>
            <div className="card-section-3-details-prizing">
                <h3>Rp 2.500.000</h3>
                <p>Rp 3.500.000</p>
            </div>
        </div>
        <div className="card-section-3-hover">
            <button>Add to cart</button>
            <div className="card-section-3-hover-det">
                <img src={like} alt="" />
            </div>
        </div>
    </div>
    <div className="card-section-3">
        <div className="card-section-3-img">
            <img src={card1} alt="" />
        </div>
        <div className="card-section-3-details">
            <div className="card-section-3-details-heading">
                <h1>Syltherine</h1>
                <p>Stylish cafe chair</p>
            </div>
            <div className="card-section-3-details-prizing">
                <h3>Rp 2.500.000</h3>
                <p>Rp 3.500.000</p>
            </div>
        </div>
        <div className="card-section-3-hover">
            <button>Add to cart</button>
            <div className="card-section-3-hover-det">
                <img src={like} alt="" />
            </div>
        </div>
    </div>
</div>
<div className="main-section-3-btn">
    <button>Show More</button>
</div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ProSec4
