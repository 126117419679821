import React, { useState, useEffect } from 'react';
import croxx from '../../imgages/Croxx.png';
import Group146 from '../../imgages/Group 146.png';
import img from '../../imgages/xrocc.png';

const ADDTOCARD = ({ count }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const newItems = Array.from({ length: count }, (_, i) => ({
      id: i + 1,
      name: "Asgaard sofa",
      price: 250000.00,
    }));
    setItems(newItems);
  }, [count]); 

  const CardDis = (index) => {
    setItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  const subtotal = items.reduce((total, item) => total + item.price, 0);

  return (
    <>
      <section className="AddtoCard">
        <div className="card-ADD">
          <div className="card-ADD-up">
            <div className="card-ADD-up-heading">
              <h1>Shopping Cart</h1>
              <div className="cancle">
                <img src={croxx} alt="" />
              </div>
            </div>
            <div className="card-ADD-up-total">
              {items.map((item, index) => (
                <div className="card-ADD-up-total-1" key={item.id}>
                  <div className="card-ADD-up-total-1-img">
                    <img src={Group146} alt="" />
                  </div>
                  <div className="card-ADD-up-total-1-des">
                    <h1>{item.name}</h1>
                    <p>1 X <span>Rs. {item.price.toLocaleString()}</span></p>
                  </div>
                  <div className="card-ADD-up-total-1-xrocc">
                    <img
                      onClick={() => CardDis(index)}
                      src={img}
                      alt=""
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="card-ADD-down">
            <div className="card-ADD-down-heading">
              <h2>Subtotal</h2>
              <h1>Rs. {subtotal.toLocaleString()}</h1>
            </div>
            <div className="card-ADD-down-btns">
              <button>Cart</button>
              <button>Checkout</button>
              <button>Comparison</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ADDTOCARD;
