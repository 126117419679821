import React from 'react'
import { IoIosArrowForward } from "react-icons/io";
const Shophero = () => {
  return (
    <>
      <section className="Shop-Hero">
        <h1>Shop</h1>
        <div className="shop-home">
        <p>Home </p><IoIosArrowForward />
       <h6>Shop</h6> 
       </div>
      </section>
    </>
  )
}

export default Shophero
