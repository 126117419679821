import React from 'react'

const Herosection = () => {
  return (
    <>
     <section className='Hero-section'>
        <div className="main-herosection">
            <div className="main-herosection-heading">
                <h3>New Arrival</h3>
                <h1>Discover Our </h1>
                <h1>New Collection</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis.</p>
            </div>
            <div className="main-herosection-btn">
                <button>BUY Now</button>
            </div>
        </div>
        </section> 
    </>
  )
}

export default Herosection
