import React from 'react'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import Shophero from '../components/ShopHero/Shophero'
import Shopsection1 from '../components/Shop-Section-1/Shopsection1'
import Shopsection2 from '../components/Shop-Section-2/Shopsection2'
import Shopsection3 from '../components/Shop-Section-3/Shopsection3'

const Shop = () => {
  return (
    <>
<Header/>
<Shophero/>
<Shopsection1/>
<Shopsection2/>
<Shopsection3/>
<Footer/>
    </>
  )
}

export default Shop
