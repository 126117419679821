import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Landing from '../src/RTS/Landing'
import Shop from '../src/RTS/Shop'
import Product from './RTS/Product'


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/product" element={<Product />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
