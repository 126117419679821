import React from 'react'
import Header from '../components/header/Header'
import ProSec1 from '../components/Pro-sec-1/ProSec1'
import ProSec2 from '../components/Pro-sec-2/ProSec2'
import ProSec3 from '../components/Pro-sec-3/ProSec3'
import ProSec4 from '../components/Pro-sec-4/ProSec4'
import Footer from '../components/footer/Footer'
// import ADDTOCARD from '../components/AddtoCard/ADDTOCARD'

const Product = () => {
  return (
    <>
    {/* <ADDTOCARD/> */}
       <Header/>   
       <ProSec1/>   
       <ProSec2/>   
       <ProSec3/>   
       <ProSec4/>   
       <Footer/>
    </>
  )
}

export default Product
