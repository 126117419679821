import React from 'react'
import filter from '../../imgages/filter.png'
import dots from '../../imgages/4dots.png'
import ioi from '../../imgages/ioi.png'

const Shopsection1 = () => {
  return (
    <>
      <section className="Shop-section-1">
        <div className="main-shop-section-1">
            <div className="main-shop-section-1-left">
                <div className="dbd-1">
                    <div className="filter">

                        <img src={filter} alt="" />
                    <h1>Filter</h1>

                    </div>
                    <div className="dots">
                        <img src={dots} alt="" />
                    </div>
                    <div className="ioi">
                        <img src={ioi} alt="" />
                    </div>
                </div>
                <div className="dbd-2">
                    <p>Showing 1–16 of 32 results</p>
                </div>
            </div>
            <div className="main-shop-section-1-right">
                <div className="main-shop-section-1-right-feild-1">
                    <h1>Show</h1>
                    <input placeholder='16' type="text" />
                </div>
                <div className="main-shop-section-1-right-feild-2">
                <h1>Short by</h1>
                <input placeholder='Default' type="text" />
                </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default Shopsection1
